<template>
  <v-btn outlined fab color="white" @click="goBack">
    <v-icon class="back-button-icon" x-large>fal fa-arrow-circle-left</v-icon>
  </v-btn>
</template>

<script>
export default {
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  border: 0;
}
.back-button-icon {
  height: 40px !important;
  width: 40px !important;
}
</style>
