import fileExport from './fileExport';

const csvExport = {
  arrayToCsvFile: (dataArray, delimiter, filename, addDelimiterBeforeLineBreak, useIso88591) => {
    const csv = createCsv(dataArray, delimiter, addDelimiterBeforeLineBreak);
    fileExport.exportString(csv, filename, useIso88591);
  },
};

const createCsv = (rows, delimiter, addDelimiterBeforeLineBreak) => {
  let returnStr = '';
  rows.forEach((row) => {
    row.forEach((field) => {
      returnStr += field + delimiter;
    });
    if (!addDelimiterBeforeLineBreak) {
      returnStr = returnStr.slice(0, -1);
    }
    returnStr += '\r\n';
  });
  return returnStr;
};

export default csvExport;
