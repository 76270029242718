import confirmDialog from '@/components/dialog/confirmDialog';
import textInput from '@/lib/ui/textArea';
import { translate as t } from '@/util/i18n';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';
import { ap, cr } from '@/lib/ui/dom';

const sendAlertUpdateDialog = (alertId) =>
  new Promise((resolve) => {
    let message = '';
    const onChangeMessage = (event) => {
      message = event.target.value;
    };
    confirmDialog(
      t('sendUpdate'),
      ap(
        cr(),
        t('sendUpdateMsg'),
        textInput(
          {
            labelText: t('message'),
            onChange: onChangeMessage,
          },
          {
            name: 'message',
          },
        ).element,
      ),
      t('cancel'),
      t('sendUpdate'),
    )
      .open()
      .then((confirm) => {
        if (confirm) {
          api
            .sendAlertUpdate({
              id: alertId,
              message,
              close: false,
              requestPosition: false,
            })
            .then(() => resolve())
            .catch((res) =>
              errorMessage(t('sendAlertUpdateError'), t('sendAlertUpdateErrorMsg'), res),
            );
        }
      });
  });

export default sendAlertUpdateDialog;
