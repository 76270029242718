<template>
  <div class="bgr">
    <v-container fluid class="pa-8 px-0">
      <v-row class="px-8">
        <GoBack />
        <v-spacer />
        <h2 class="py-4 ma-0 d-none d-sm-block">{{ $t('deliveryreports') }}</h2>
        <v-spacer />
        <v-btn
          class="ma-4"
          :class="{ 'rotate-animation': processing > 0 }"
          outlined
          :loading="loading"
          :disabled="loading"
          fab
          width="45"
          height="45"
          @click="getAlertData"
        >
          <v-icon>fal fa-refresh </v-icon>
        </v-btn>
      </v-row>

      <v-tabs
        v-if="appChannelMessages.length > 0 || smsChannelMessages.length > 0"
        v-model="tab"
        class="mt-4"
        fixed-tabs
        center-active
        background-color="#d2d2d2"
        color="Black"
        centered
        light
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab href="#users">
          <v-icon class="mr-4">fal fa-users</v-icon> {{ $t('recipients') }}
        </v-tab>

        <v-tab href="#app">
          <v-icon class="mr-4"> fal fa-mobile</v-icon>
          App
        </v-tab>

        <v-tab href="#sms">
          <v-icon class="mr-4">fa-sms</v-icon>
          SMS
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :value="'users'" class="pa-8">
          <v-row v-if="overviewMessages.length > 0" align="center">
            <v-col cols="12" md="6">
              <h3 class="text-center">{{ $t('recipients') }}</h3>
              <DoughnutChart
                v-if="!loading"
                :chart-data="overviewChartdata"
                :chart-options="{
                  responsive: true,
                  maintainAspectRatio: false,
                  cutout: '50%',
                  plugins: {
                    legend: {
                      display: false,
                      position: 'bottom',
                      align: 'center',
                      // labels: {
                      //   font: {
                      //     size: 16,
                      //   },
                      //   generateLabels(chart) {
                      //     let newLegends = [];
                      //     chart.data.labels.forEach(function (label, index) {
                      //       if (chart.data.datasets[0].data[index] == 0)
                      //         //zero values
                      //         return;
                      //       var legend = {
                      //         text: label + ' (' + chart.data.datasets[0].data[index] + ') ',
                      //         fillStyle: chart.data.datasets[0].backgroundColor[index],
                      //       };
                      //       newLegends.push(legend);
                      //     });
                      //     return newLegends;
                      //   },
                      // },
                    },
                  },
                }"
              />
            </v-col>
            <v-col cols="12" md="6">
              <table>
                <tbody>
                  <tr v-for="(legend, index) in overviewLegends" :key="index">
                    <td width="35px">
                      <v-avatar class="my-1 status-avatar" :color="legend.color" size="30" tile />
                    </td>
                    <td class="text-right pl-2 pr-1 font-weight-bold">{{ legend.value }}</td>
                    <td>{{ legend.label }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>

          <v-data-table
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [-1, 10, 25, 50, 100],
            }"
            :items-per-page="1"
            :headers="overviewMessagesHeaders"
            :items="overviewMessages"
            item-key="userName"
            sort-by="userName"
            class="elevation-1 mt-4"
          >
            <template #no-data> No message details found </template>

            <template #[`item.userName`]="{ item }">
              <profile-photo
                :key="item.userName"
                :url="item.profilePhoto"
                :alt="item.userName"
                :size="'40px'"
                :light-border="true"
                :clickable="true"
                class="mr-4"
              />
              <span>{{ item.userName }}</span>
            </template>
            <template #[`item.bestStatus`]="{ item }">
              <v-avatar
                class="ma-2 ml-0 status-avatar"
                :color="statusAndChannelEnumColor(item.bestStatus, item.alertChannel)"
                size="18"
              />
              <span>{{ parseStatusEnum(item.bestStatus) }}</span>
            </template>
            <template #[`item.alertChannel`]="{ item }">
              <span>{{ parseChannelEnum(item.alertChannel) }}</span>
            </template>

            <template #[`item.updateTS`]="{ item }">
              {{ item.updateTS.format('HH:mm:ss') }}
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item :value="'app'" class="pa-8">
          <v-row v-if="appChannelMessages.length > 0" align="center">
            <v-col cols="12" md="6">
              <h3 class="text-center">{{ $t('statusPrDevice') }}</h3>
              <DoughnutChart
                v-if="!loading"
                :chart-data="pushChartdata"
                :chart-options="{
                  responsive: true,
                  maintainAspectRatio: false,
                  cutout: '50%',
                  plugins: {
                    legend: {
                      display: false,
                      // position: 'bottom',
                      // align: 'center',
                      // padding: 25,
                      // labels: {
                      //   font: {
                      //     size: 16,
                      //   },
                      //   generateLabels(chart) {
                      //     let newLegends = [];
                      //     chart.data.labels.forEach(function (label, index) {
                      //       if (chart.data.datasets[0].data[index] == 0)
                      //         //zero values
                      //         return;
                      //       var legend = {
                      //         text: label + ' (' + chart.data.datasets[0].data[index] + ') ',
                      //         fillStyle: chart.data.datasets[0].backgroundColor[index],
                      //       };
                      //       newLegends.push(legend);
                      //     });
                      //     return newLegends;
                      //   },
                      // },
                    },
                  },
                }"
              />
            </v-col>
            <v-col cols="12" md="6">
              <table>
                <tbody>
                  <tr v-for="(legend, index) in appChartLegends" :key="index">
                    <td width="35px">
                      <v-avatar class="my-1 status-avatar" :color="legend.color" size="30" tile />
                    </td>
                    <td class="text-right pl-2 pr-1 font-weight-bold">{{ legend.value }}</td>
                    <td>{{ legend.label }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="6" offset="3">
              <h4 class="text-center">No app messages sent</h4>
            </v-col>
          </v-row>
          <v-row v-if="appChannelMessages.length > 0">
            <v-col>
              <v-data-table
                :loading="loading"
                :headers="appChannelMessagesHeaders"
                :items="appChannelMessages"
                :footer-props="{
                  'items-per-page-options': [-1, 10, 25, 50, 100],
                }"
                :items-per-page="1"
                item-key="messageId"
                sort-by="userName"
                class="elevation-1 mt-4"
              >
                <template #no-data> No message details found </template>

                <template #[`item.status`]="{ item }">
                  <v-avatar
                    class="ma-2 ml-0 status-avatar"
                    :color="statusAndChannelEnumColor(item.status, item.alertChannel)"
                    size="18"
                  />
                  <span>{{ parseStatusEnum(item.status) }}</span>
                </template>

                <template #[`item.updateTS`]="{ item }">
                  {{ item.updateTS.format('HH:mm:ss') }}
                </template>

                <template #[`item.deviceName`]="{ item }">
                  <span v-if="item.deviceName"> {{ item.deviceName }}</span>
                  <span v-else> {{ item.deviceId }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'sms'" class="pa-8">
          <v-row v-if="smsChannelMessages.length > 0" align="center">
            <v-col cols="12" md="6">
              <h3 class="text-center">{{ $t('smsDelivery') }}</h3>
              <DoughnutChart
                v-if="!loading"
                :chart-data="smsChartdata"
                :chart-options="{
                  responsive: true,
                  maintainAspectRatio: false,
                  cutout: '50%',
                  plugins: {
                    legend: {
                      display: false,
                      // position: 'bottom',
                      // labels: {
                      //   font: {
                      //     size: 16,
                      //   },
                      //   generateLabels(chart) {
                      //     let newLegends = [];
                      //     chart.data.labels.forEach(function (label, index) {
                      //       if (chart.data.datasets[0].data[index] == 0)
                      //         //zero values
                      //         return;
                      //       var legend = {
                      //         text: label + ' (' + chart.data.datasets[0].data[index] + ') ',
                      //         fillStyle: chart.data.datasets[0].backgroundColor[index],
                      //       };
                      //       newLegends.push(legend);
                      //     });
                      //     return newLegends;
                      //   },
                      // },
                    },
                  },
                }"
              />
            </v-col>
            <v-col cols="12" md="6">
              <table>
                <tbody>
                  <tr v-for="(legend, index) in smsChartLegends" :key="index">
                    <td width="35px">
                      <v-avatar class="my-1 status-avatar" :color="legend.color" size="30" tile />
                    </td>
                    <td class="text-right pl-2 pr-1 font-weight-bold">{{ legend.value }}</td>
                    <td>{{ legend.label }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="6" offset="3">
              <!-- <h4 class="text-center">No SMS sent</h4> -->
              <empty-state
                :title="$t('noSmsSent')"
                :message="$t('noSmsSentMsg')"
                :icon-src="require('@/assets/images/empty-states/sms.svg')"
              />
            </v-col>
          </v-row>
          <v-row v-if="smsChannelMessages">
            <v-col>
              <v-data-table
                :loading="loading"
                :footer-props="{
                  'items-per-page-options': [-1, 10, 25, 50, 100],
                }"
                :items-per-page="1"
                :headers="smsChannelMessagesHeaders"
                :items="smsChannelMessages"
                item-key="messageId"
                sort-by="userName"
                class="elevation-1 mt-4"
              >
                <template #no-data> No message details found </template>

                <template #[`item.status`]="{ item }">
                  <v-avatar
                    class="ma-2 ml-0 status-avatar"
                    :color="statusAndChannelEnumColor(item.status, item.channel)"
                    size="18"
                  />
                  <span>{{ parseStatusEnum(item.status) }}</span>
                </template>

                <template #[`item.updateTS`]="{ item }">
                  {{ item.updateTS.format('HH:mm:ss') }}
                </template>

                <template #[`item.deviceName`]="{ item }">
                  <span v-if="item.deviceName"> {{ item.deviceName }}</span>
                  <span v-else> {{ item.deviceId }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>
<script>
import is from '@/lib/util/is';
import api from '@/util/api';
import moment from 'moment';
import GoBack from '@/components/GoBack.vue';
import * as colors from '@/lib/ui/colors';
import DoughnutChart from '@/components/DoughnutChart.vue';
import EmptyState from '@/components/EmptyState';
import ProfilePhoto from '@/components/ProfilePhoto.vue';

export default {
  name: 'DeliveryDetails',

  components: {
    GoBack,
    DoughnutChart,
    EmptyState,
    ProfilePhoto,
  },

  data: () => ({
    alertId: 0,
    loading: false,
    tab: null,
    appChannelMessages: [],
    smsChannelMessages: [],
    overviewMessages: [],
    appChannelMessagesHeaders: [
      { text: 'User', value: 'userName' },
      { text: 'Status', value: 'status' },
      { text: 'Device', value: 'deviceName' },
      { text: 'OS', value: 'deviceOS' },
      { text: 'Timestamp', value: 'updateTS', align: 'right' },
    ],
    smsChannelMessagesHeaders: [
      { text: 'User', value: 'userName' },
      { text: 'Status', value: 'status' },
      { text: 'Phone number', value: 'deviceName' },
      { text: 'Timestamp', value: 'updateTS', align: 'right' },
    ],
    overviewMessagesHeaders: [
      { text: 'User', value: 'userName' },
      { text: 'Status', value: 'bestStatus' },
      { text: 'Channel', value: 'alertChannel' },
      { text: 'Timestamp', value: 'updateTS', align: 'right' },
    ],
    overviewLegends: [],
    appChartLegends: [],
    smsChartLegends: [],
    pushChartdata: null,
    smsChartdata: null,
    overviewChartdata: null,
    timerVar: null,
    processing: 1,
  }),
  computed: {
    totalReceived: function () {
      return this.overviewMessages.filter((message) => {
        return message.bestStatus === 'delivered';
      }).length;
    },
    totalSent: function () {
      return this.overviewMessages.length;
    },
  },
  mounted() {
    this.alert = 0;
    if (is.number(this.$route.params.id)) {
      this.alertId = this.$route.params.id;
      this.getAlertData(true);
    }
  },
  beforeDestroy() {
    if (this.timerVar) {
      clearTimeout(this.timerVar);
    }
  },
  methods: {
    moment,
    getAlertData(showLoading) {
      if (showLoading) this.loading = true;
      api
        .getAlertMessages(this.alertId)
        .then((result) => {
          let alertMessages = result.messages;

          this.getOverviewData(alertMessages);
          this.getAppData(alertMessages);
          this.getSmsData(alertMessages);

          if (showLoading) this.loading = false;

          if (this.overviewMessages.length > 0) {
            this.processing = this.overviewMessages.filter((message) => {
              return message.bestStatus === 'sent';
            }).length;
          }

          if (this.processing > 0) {
            this.timerVar = setTimeout(() => {
              this.getAlertData(false);
            }, 5000);
          } else {
            console.log('clearing timer', this.timerVar);
            clearTimeout(this.timerVar);
            this.timerVar = null;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$router.push('/notfound');
        });
    },
    getOverviewData(alertMessages) {
      this.overviewMessages = [];
      let tempUserMessages = alertMessages
        .map((message) => {
          return message.userName;
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      tempUserMessages.forEach((user) => {
        let statuses = alertMessages.filter((message) => {
          return message.userName === user;
        });
        let bestStatus = 'sent';
        let alertChannel = 'push';
        let updateTS = statuses[0].updateTS;
        let profilePhoto = statuses[0].userProfilePhoto;
        statuses.forEach((message) => {
          if (message.status === 'delivered' || message.status === 'noPos') {
            bestStatus = 'delivered';
            updateTS = message.updateTS;
            alertChannel = message.channel;
          } else if (message.status === 'noTarget') {
            if (bestStatus !== 'delivered') {
              bestStatus = 'noTarget';
              updateTS = message.updateTS;
              alertChannel = message.channel;
            }
          } else if (
            message.status === 'expired' ||
            message.status === 'rejected' ||
            message.status === 'error'
          ) {
            if (bestStatus !== 'delivered' && bestStatus !== 'noTarget') {
              bestStatus = 'error';
              updateTS = message.updateTS;
              alertChannel = message.channel;
            }
          } else if (message.status === 'fallback') {
            if (bestStatus !== 'delivered' && bestStatus !== 'noTarget' && bestStatus !== 'error') {
              bestStatus = 'fallback';
              updateTS = message.updateTS;
              alertChannel = message.channel;
            }
          }
        });
        this.overviewMessages.push({
          userName: user,
          bestStatus: bestStatus,
          updateTS: updateTS,
          alertChannel: alertChannel,
          profilePhoto: profilePhoto,
        });
      });

      const overviewStats = {
        'Received in app':
          this.overviewMessages.filter((message) => {
            return message.alertChannel === 'push' && message.bestStatus === 'delivered';
          }).length +
          this.overviewMessages.filter((message) => {
            return message.alertChannel === 'push' && message.bestStatus === 'noPos';
          }).length,
        'Received as SMS':
          this.overviewMessages.filter((message) => {
            return message.alertChannel === 'sms' && message.bestStatus === 'delivered';
          }).length +
          this.overviewMessages.filter((message) => {
            return message.alertChannel === 'sms' && message.bestStatus === 'noPos';
          }).length,
        'Out of target': this.overviewMessages.filter((message) => {
          return message.alertChannel === 'push' && message.bestStatus === 'noTarget';
        }).length,
        Failed:
          this.overviewMessages.filter((message) => {
            return message.bestStatus === 'expired';
          }).length +
          this.overviewMessages.filter((message) => {
            return message.bestStatus === 'rejected';
          }).length +
          this.overviewMessages.filter((message) => {
            return message.bestStatus === 'error';
          }).length,
        Sent: this.overviewMessages.filter((message) => {
          return message.bestStatus === 'sent';
        }).length,
      };

      this.overviewLegends = [];
      Object.keys(overviewStats).forEach((stat) => {
        this.overviewLegends.push({
          label: this.$t(stat.replaceAll(' ', '').toLowerCase()),
          color: colors[stat.replaceAll(' ', '').toLowerCase()],
          value: overviewStats[stat],
        });
      });

      this.overviewChartdata = {
        labels: [
          this.$t('receivedinapp'),
          this.$t('receivedassms'),
          this.$t('outoftarget'),
          this.$t('failed'),
          this.$t('sent'),
        ],
        datasets: [
          {
            backgroundColor: [
              colors.deliveredApp,
              colors.deliveredSms,
              colors.outOfTarget,
              colors.failed,
              colors.sent,
            ],
            borderColor: [colors.gray400],
            borderWidth: 1,
            data: Object.keys(overviewStats).map((stat) => overviewStats[stat]),
          },
        ],
      };
    },
    getAppData(alertMessages) {
      this.appChannelMessages = alertMessages.filter((message) => {
        return message.channel === 'push';
      });
      const appDeviceStats = {
        Received:
          this.appChannelMessages.filter((message) => {
            return message.status === 'delivered';
          }).length +
          this.appChannelMessages.filter((message) => {
            return message.status === 'noPos';
          }).length,

        'Out of target': this.appChannelMessages.filter((message) => {
          return message.status === 'noTarget';
        }).length,
        Failed:
          this.appChannelMessages.filter((message) => {
            return message.status === 'expired';
          }).length +
          this.appChannelMessages.filter((message) => {
            return message.status === 'rejected';
          }).length +
          this.appChannelMessages.filter((message) => {
            return message.status === 'error';
          }).length,
        'Fallback SMS': this.appChannelMessages.filter((message) => {
          return message.status === 'fallback';
        }).length,
        Sent: this.appChannelMessages.filter((message) => {
          return message.status === 'sent';
        }).length,
      };

      this.appChartLegends = [];
      Object.keys(appDeviceStats).forEach((stat) => {
        this.appChartLegends.push({
          label: this.$t(stat.replaceAll(' ', '').toLowerCase()),
          color: colors[stat.replaceAll(' ', '').toLowerCase()],
          value: appDeviceStats[stat],
        });
      });

      this.pushChartdata = {
        labels: [
          this.$t('received'),
          this.$t('outoftarget'),
          this.$t('failed'),
          this.$t('fallback'),
          this.$t('sent'),
        ],
        datasets: [
          {
            backgroundColor: [
              colors.deliveredApp,
              colors.outOfTarget,
              colors.failed,
              colors.fallback,
              colors.sent,
            ],
            borderColor: [colors.gray400],
            borderWidth: 1,
            data: Object.keys(appDeviceStats).map((stat) => appDeviceStats[stat]),
          },
        ],
      };
    },
    getSmsData(alertMessages) {
      this.smsChannelMessages = alertMessages.filter((message) => {
        return message.channel === 'sms';
      });

      const smsDeviceStats = {
        'Received as sms': this.smsChannelMessages.filter((message) => {
          return message.status === 'delivered';
        }).length,
        Processing: this.smsChannelMessages.filter((message) => {
          return message.status === 'dispatched';
        }).length,
        Failed:
          this.smsChannelMessages.filter((message) => {
            return message.status === 'expired';
          }).length +
          this.smsChannelMessages.filter((message) => {
            return message.status === 'rejected';
          }).length +
          this.smsChannelMessages.filter((message) => {
            return message.status === 'error';
          }).length,
      };

      this.smsChartLegends = [];
      Object.keys(smsDeviceStats).forEach((stat) => {
        this.smsChartLegends.push({
          label: this.$t(stat.replaceAll(' ', '').toLowerCase()),
          color: colors[stat.replaceAll(' ', '').toLowerCase()],
          value: smsDeviceStats[stat],
        });
      });

      this.smsChartdata = {
        labels: [this.$t('received'), this.$t('processing'), this.$t('failed')],
        datasets: [
          {
            backgroundColor: [colors.deliveredSms, colors.sent, colors.failed],
            borderColor: [colors.gray400],
            borderWidth: 1,
            data: Object.keys(smsDeviceStats).map((stat) => smsDeviceStats[stat]),
          },
        ],
      };
    },

    statusAndChannelEnumColor(status, channel) {
      if (status === 'fallback') return colors.fallback;
      if (status === 'delivered' || status === 'confirmed') {
        if (channel === 'sms') {
          return colors.deliveredSms;
        } else {
          return colors.deliveredApp;
        }
      }
      if (status === 'noPos') return colors.deliveredApp;
      if (status === 'noTarget') return colors.outOfTarget;
      if (status == 'error' || status === 'rejected' || status === 'expired')
        return colors.redPrimary;
      if (status === 'sent') return colors.sent;
      return colors.blueLight;
    },
    parseStatusEnum(status) {
      switch (status) {
        case 'error':
          return this.$t('error');
        case 'rejected':
          return this.$t('rejected');
        case 'expired':
          return this.$t('expired');
        case 'noPos':
          return this.$t('receivedNoPos)');
        case 'noTarget':
          return this.$t('outoftarget');
        case 'sent':
          return this.$t('sent');
        case 'dispatched':
          return this.$t('dispatched');
        case 'delivered':
          return this.$t('received');
        case 'confirmed':
          return this.$t('confirmed');
        case 'fallback':
          return this.$t('fallback');
        default:
          return this.$t('unknown');
      }
    },
    parseChannelEnum(channel) {
      switch (channel) {
        case 1:
          return 'App';
        case 2:
          return 'SMS';
        case 'push':
          return 'App';
        case 'sms':
          return 'SMS';
        default:
          return 'Unknown';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/scss/settings/colors';
.bgr {
  background-color: #f1f1f1 !important;
  button {
    color: #353535 !important;
  }
}
.status-avatar {
  border: 1px solid #bdbdbd !important;
}
.rotate-animation {
  animation: rotate-animation 5s infinite linear;
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.legend-item {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.legendBox {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  // margin-right: 8px;
  border: 1px solid #ababab;
  &.success-app {
    background-color: $deliveredApp !important;
  }
  &.success-sms {
    background-color: $deliveredSms !important;
  }
  &.failed {
    background-color: $failed !important;
  }
  &.processing {
    background-color: $sent !important;
  }
}
// td,
// th {
//   // border: 1px solid #dddddd;
//   // border-bottom: 1px solid #ddd;
//   text-align: left;
//   padding-top: 4px;
//   // padding: 4px;
// }
</style>
