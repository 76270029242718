var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgr"},[_c('v-container',{staticClass:"pa-8 px-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-8"},[_c('GoBack'),_c('v-spacer'),_c('h2',{staticClass:"py-4 ma-0 d-none d-sm-block"},[_vm._v(_vm._s(_vm.$t('deliveryreports')))]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-4",class:{ 'rotate-animation': _vm.processing > 0 },attrs:{"outlined":"","loading":_vm.loading,"disabled":_vm.loading,"fab":"","width":"45","height":"45"},on:{"click":_vm.getAlertData}},[_c('v-icon',[_vm._v("fal fa-refresh ")])],1)],1),(_vm.appChannelMessages.length > 0 || _vm.smsChannelMessages.length > 0)?_c('v-tabs',{staticClass:"mt-4",attrs:{"fixed-tabs":"","center-active":"","background-color":"#d2d2d2","color":"Black","centered":"","light":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{attrs:{"href":"#users"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("fal fa-users")]),_vm._v(" "+_vm._s(_vm.$t('recipients'))+" ")],1),_c('v-tab',{attrs:{"href":"#app"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(" fal fa-mobile")]),_vm._v(" App ")],1),_c('v-tab',{attrs:{"href":"#sms"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("fa-sms")]),_vm._v(" SMS ")],1)],1):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pa-8",attrs:{"value":'users'}},[(_vm.overviewMessages.length > 0)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('recipients')))]),(!_vm.loading)?_c('DoughnutChart',{attrs:{"chart-data":_vm.overviewChartdata,"chart-options":{
                responsive: true,
                maintainAspectRatio: false,
                cutout: '50%',
                plugins: {
                  legend: {
                    display: false,
                    position: 'bottom',
                    align: 'center',
                    // labels: {
                    //   font: {
                    //     size: 16,
                    //   },
                    //   generateLabels(chart) {
                    //     let newLegends = [];
                    //     chart.data.labels.forEach(function (label, index) {
                    //       if (chart.data.datasets[0].data[index] == 0)
                    //         //zero values
                    //         return;
                    //       var legend = {
                    //         text: label + ' (' + chart.data.datasets[0].data[index] + ') ',
                    //         fillStyle: chart.data.datasets[0].backgroundColor[index],
                    //       };
                    //       newLegends.push(legend);
                    //     });
                    //     return newLegends;
                    //   },
                    // },
                  },
                },
              }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('table',[_c('tbody',_vm._l((_vm.overviewLegends),function(legend,index){return _c('tr',{key:index},[_c('td',{attrs:{"width":"35px"}},[_c('v-avatar',{staticClass:"my-1 status-avatar",attrs:{"color":legend.color,"size":"30","tile":""}})],1),_c('td',{staticClass:"text-right pl-2 pr-1 font-weight-bold"},[_vm._v(_vm._s(legend.value))]),_c('td',[_vm._v(_vm._s(legend.label))])])}),0)])])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"loading":_vm.loading,"footer-props":{
            'items-per-page-options': [-1, 10, 25, 50, 100],
          },"items-per-page":1,"headers":_vm.overviewMessagesHeaders,"items":_vm.overviewMessages,"item-key":"userName","sort-by":"userName"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No message details found ")]},proxy:true},{key:`item.userName`,fn:function({ item }){return [_c('profile-photo',{key:item.userName,staticClass:"mr-4",attrs:{"url":item.profilePhoto,"alt":item.userName,"size":'40px',"light-border":true,"clickable":true}}),_c('span',[_vm._v(_vm._s(item.userName))])]}},{key:`item.bestStatus`,fn:function({ item }){return [_c('v-avatar',{staticClass:"ma-2 ml-0 status-avatar",attrs:{"color":_vm.statusAndChannelEnumColor(item.bestStatus, item.alertChannel),"size":"18"}}),_c('span',[_vm._v(_vm._s(_vm.parseStatusEnum(item.bestStatus)))])]}},{key:`item.alertChannel`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.parseChannelEnum(item.alertChannel)))])]}},{key:`item.updateTS`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.updateTS.format('HH:mm:ss'))+" ")]}}],null,true)})],1),_c('v-tab-item',{staticClass:"pa-8",attrs:{"value":'app'}},[(_vm.appChannelMessages.length > 0)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('statusPrDevice')))]),(!_vm.loading)?_c('DoughnutChart',{attrs:{"chart-data":_vm.pushChartdata,"chart-options":{
                responsive: true,
                maintainAspectRatio: false,
                cutout: '50%',
                plugins: {
                  legend: {
                    display: false,
                    // position: 'bottom',
                    // align: 'center',
                    // padding: 25,
                    // labels: {
                    //   font: {
                    //     size: 16,
                    //   },
                    //   generateLabels(chart) {
                    //     let newLegends = [];
                    //     chart.data.labels.forEach(function (label, index) {
                    //       if (chart.data.datasets[0].data[index] == 0)
                    //         //zero values
                    //         return;
                    //       var legend = {
                    //         text: label + ' (' + chart.data.datasets[0].data[index] + ') ',
                    //         fillStyle: chart.data.datasets[0].backgroundColor[index],
                    //       };
                    //       newLegends.push(legend);
                    //     });
                    //     return newLegends;
                    //   },
                    // },
                  },
                },
              }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('table',[_c('tbody',_vm._l((_vm.appChartLegends),function(legend,index){return _c('tr',{key:index},[_c('td',{attrs:{"width":"35px"}},[_c('v-avatar',{staticClass:"my-1 status-avatar",attrs:{"color":legend.color,"size":"30","tile":""}})],1),_c('td',{staticClass:"text-right pl-2 pr-1 font-weight-bold"},[_vm._v(_vm._s(legend.value))]),_c('td',[_vm._v(_vm._s(legend.label))])])}),0)])])],1):_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"3"}},[_c('h4',{staticClass:"text-center"},[_vm._v("No app messages sent")])])],1),(_vm.appChannelMessages.length > 0)?_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"loading":_vm.loading,"headers":_vm.appChannelMessagesHeaders,"items":_vm.appChannelMessages,"footer-props":{
                'items-per-page-options': [-1, 10, 25, 50, 100],
              },"items-per-page":1,"item-key":"messageId","sort-by":"userName"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No message details found ")]},proxy:true},{key:`item.status`,fn:function({ item }){return [_c('v-avatar',{staticClass:"ma-2 ml-0 status-avatar",attrs:{"color":_vm.statusAndChannelEnumColor(item.status, item.alertChannel),"size":"18"}}),_c('span',[_vm._v(_vm._s(_vm.parseStatusEnum(item.status)))])]}},{key:`item.updateTS`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.updateTS.format('HH:mm:ss'))+" ")]}},{key:`item.deviceName`,fn:function({ item }){return [(item.deviceName)?_c('span',[_vm._v(" "+_vm._s(item.deviceName))]):_c('span',[_vm._v(" "+_vm._s(item.deviceId))])]}}],null,true)})],1)],1):_vm._e()],1),_c('v-tab-item',{staticClass:"pa-8",attrs:{"value":'sms'}},[(_vm.smsChannelMessages.length > 0)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('smsDelivery')))]),(!_vm.loading)?_c('DoughnutChart',{attrs:{"chart-data":_vm.smsChartdata,"chart-options":{
                responsive: true,
                maintainAspectRatio: false,
                cutout: '50%',
                plugins: {
                  legend: {
                    display: false,
                    // position: 'bottom',
                    // labels: {
                    //   font: {
                    //     size: 16,
                    //   },
                    //   generateLabels(chart) {
                    //     let newLegends = [];
                    //     chart.data.labels.forEach(function (label, index) {
                    //       if (chart.data.datasets[0].data[index] == 0)
                    //         //zero values
                    //         return;
                    //       var legend = {
                    //         text: label + ' (' + chart.data.datasets[0].data[index] + ') ',
                    //         fillStyle: chart.data.datasets[0].backgroundColor[index],
                    //       };
                    //       newLegends.push(legend);
                    //     });
                    //     return newLegends;
                    //   },
                    // },
                  },
                },
              }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('table',[_c('tbody',_vm._l((_vm.smsChartLegends),function(legend,index){return _c('tr',{key:index},[_c('td',{attrs:{"width":"35px"}},[_c('v-avatar',{staticClass:"my-1 status-avatar",attrs:{"color":legend.color,"size":"30","tile":""}})],1),_c('td',{staticClass:"text-right pl-2 pr-1 font-weight-bold"},[_vm._v(_vm._s(legend.value))]),_c('td',[_vm._v(_vm._s(legend.label))])])}),0)])])],1):_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"3"}},[_c('empty-state',{attrs:{"title":_vm.$t('noSmsSent'),"message":_vm.$t('noSmsSentMsg'),"icon-src":require('@/assets/images/empty-states/sms.svg')}})],1)],1),(_vm.smsChannelMessages)?_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"loading":_vm.loading,"footer-props":{
                'items-per-page-options': [-1, 10, 25, 50, 100],
              },"items-per-page":1,"headers":_vm.smsChannelMessagesHeaders,"items":_vm.smsChannelMessages,"item-key":"messageId","sort-by":"userName"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No message details found ")]},proxy:true},{key:`item.status`,fn:function({ item }){return [_c('v-avatar',{staticClass:"ma-2 ml-0 status-avatar",attrs:{"color":_vm.statusAndChannelEnumColor(item.status, item.channel),"size":"18"}}),_c('span',[_vm._v(_vm._s(_vm.parseStatusEnum(item.status)))])]}},{key:`item.updateTS`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.updateTS.format('HH:mm:ss'))+" ")]}},{key:`item.deviceName`,fn:function({ item }){return [(item.deviceName)?_c('span',[_vm._v(" "+_vm._s(item.deviceName))]):_c('span',[_vm._v(" "+_vm._s(item.deviceId))])]}}],null,true)})],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }