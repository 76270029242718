import { encode } from 'iconv-lite';

const fileExport = {
  exportString: (str, fileName, useIso88591) => {
    let charset;
    if (useIso88591) {
      str = encode(new Buffer(str), 'iso-8859-1');
      charset = 'iso-8859-1';
    } else {
      // https://stackoverflow.com/a/16231345/529171
      str = '\uFEFF' + str;
      charset = 'utf-8';
    }
    const pom = document.createElement('a');
    const blob = new Blob([str], { type: 'text/csv;charset=' + charset + ',' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      pom.href = URL.createObjectURL(blob);
      pom.setAttribute('download', fileName);
      document.body.appendChild(pom); // Required for Firefox
      pom.click();
      pom.remove();
    }
  },
};

export default fileExport;
