<template>
  <div class="commentInput">
    <v-container>
      <div class="d-flex">
        <v-textarea
          v-model="newComment"
          class="input"
          :label="$t('writeNewComment')"
          auto-grow
          rows="1"
          outlined
          hide-details="true"
        />
      </div>
      <v-progress-linear v-if="saving" indeterminate color="primary" ma-0 />
      <v-card class="d-flex justify-space-between mt-2" flat tile>
        <v-card width="100%" outlined rounded>
          <v-card-title>{{ $t('addAttachments') }}</v-card-title>
          <v-card-text>
            <attachment-grid
              :attachments="attachments"
              editable
              preview
              @add="addAttachment"
              @remove="removeAttachment"
            />
          </v-card-text>
        </v-card>

        <v-btn
          class="px-2 ml-2 send-button"
          color="primary"
          :disabled="sendButtonDisabled"
          @click="sendComment"
          >Send</v-btn
        >
      </v-card>
    </v-container>
    <div />
  </div>
</template>

<script>
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';
import { translate as t } from '@/util/i18n';
import { BlockBlobClient } from '@azure/storage-blob';
import AttachmentGrid from './AttachmentGrid.vue';
import fileInput from '@/util/fileInput';
import getFileExtension from '@/util/getFileExtension';

async function addComment(alertId, message, attachments) {
  api
    .addComment({
      alertId,
      message,
      attachments,
    })
    .catch((res) => {
      errorMessage(t('commentError'), t('commentErrorMsg'), res);
    });
}
export default {
  name: 'CommentInput',

  components: {
    AttachmentGrid,
  },

  props: {
    alertId: {
      type: String,
      default() {
        return '0';
      },
      required: true,
    },
  },
  data: () => ({
    attachments: [],
    newComment: '',
    saving: false,
  }),
  computed: {
    sendButtonDisabled: function () {
      if (this.saving === true) return true;
      if (this.newComment.length >= 2 || this.attachments.length > 0) return false;
      else return true;
    },
  },
  methods: {
    removeAttachment(attachment) {
      this.attachments = this.attachments.filter((a) => a !== attachment);
    },

    async addAttachment() {
      const files = await fileInput();
      if (!files?.length) {
        return;
      }

      // FileList does not support forEach, so we NEED to use a for-loop
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fr = new FileReader();

        fr.addEventListener(
          'load',
          () => {
            const presignedUrl = fr.result;
            this.attachments.push({
              name: file.name,
              presignedUrl,
              file: file,
              size: file.size,
            });
          },
          false,
        );

        fr.readAsDataURL(file);
      }
    },

    sendComment: async function () {
      this.saving = true;
      let message = this.newComment.trim();

      if (this.attachments.length > 0) {
        const blobTasks = this.attachments.map((attachment) => async () => {
          const extension = getFileExtension(attachment.name);
          const res = await api.getAlertAttachmentUploadlink(this.alertId, extension);
          attachment.url = res.key;
          const blobClient = new BlockBlobClient(res.presignedUrl);
          await blobClient.uploadData(attachment.file);
        });

        await Promise.all(blobTasks.map((task) => task()));

        const attachments = this.attachments.map((attachment) => ({
          name: attachment.name,
          url: attachment.url,
          size: attachment.size ?? 0,
        }));

        await addComment(this.alertId, message, attachments);
      } else {
        await addComment(this.alertId, message, []);
      }
      this.newComment = '';
      this.attachments = null;
      this.fileRecords = [];
      this.attachments = [];
      this.saving = false;
      this.$emit('refreshComments');
    },
  },
};
</script>

<style lang="scss" scoped>
.commentInput {
  display: inline-block;
  width: 100%;
  background-color: white;
  padding: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  border-radius: 0 0 8px 8px;
  border-top: 1px solid lightgrey;
  position: sticky;
  bottom: 0px;
  z-index: 5;
}
.send-button {
  height: 65px !important;
}
</style>
