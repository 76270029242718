/* eslint-disable no-unreachable */
import is from '@/lib/util/is';
import moment from 'moment';
import csvExport from '@/util/csvExport';
import api from '@/util/api';

const DELIMITER = ';';

const alertHeaders = [
  {
    key: 'id',
    text: 'Alert ID',
  },
  {
    valueFunction: (alert) => `${alert.author} (${alert.authorId})`,
    text: 'Sent by',
  },
  {
    valueFunction: (alert) => alert.date.format(),
    text: 'Sent',
  },
  {
    key: 'type',
    text: 'Type',
  },
  {
    key: 'title',
    text: 'Title',
  },
  {
    key: 'message',
    text: 'Message',
  },
  {
    key: 'status',
    text: 'Status',
  },
];

const alertMessageHeaders = [
  {
    key: 'messageId',
    text: 'Message ID',
  },
  {
    valueFunction: (message) => `${message.userName} (${message.userId})`,
    text: 'Recipient',
  },
  {
    key: 'channel',
    text: 'Channel',
  },
  {
    valueFunction: (message) => (message.channel === 'push' ? message.deviceId : 'N/A'),
    text: 'Device',
  },
  {
    valueFunction: (message) => (message.channel === 'push' ? message.deviceOS : 'N/A'),
    text: 'Device OS',
  },
  {
    valueFunction: (message) => (message.channel === 'push' ? message.deviceName : 'N/A'),
    text: 'Device Name',
  },
  {
    key: 'status',
    text: 'Status',
  },
  {
    valueFunction: (message) => message.updateTS.format(),
    text: 'Status Updated',
  },
];

const timelineItemHeaders = [
  {
    key: 'id',
    text: 'Item ID',
  },
  {
    valueFunction: (item) => `${item.author} (${item.authorId})`,
    text: 'Sent By',
  },
  {
    valueFunction: (item) => item.time.format(),
    text: 'Sent',
  },
  {
    key: 'type',
    text: 'Type',
  },
  {
    key: 'message',
    text: 'Message',
  },
];

const getItemPropertyByHeader = (header, item) => {
  if (header.valueFunction) return header.valueFunction(item);
  else return item[header.key];
};

export default (alertId) => {
  if (!is.number(alertId) || alertId < 1) {
    return;
  }

  const date = moment().format();

  const filename = `TeamAlert_Export_for_alert_${alertId}_${date}.csv`;

  Promise.all([
    api.getAlert(alertId),
    api.getAlertMessages(alertId),
    api.getComments({
      page: 1,
      pageSize: 1000,
      alertId,
    }),
  ]).then((results) => {
    const alert = results[0];
    const messages = results[1].messages;
    const timelineItems = results[2].comments;

    const exportData = [];

    // Alert
    exportData.push(['Alert Details']);
    exportData.push(alertHeaders.map((header) => header.text));
    exportData.push(alertHeaders.map((header) => getItemPropertyByHeader(header, alert)));

    exportData.push([]);

    // Alert messages
    exportData.push(['Recipients']);
    exportData.push(alertMessageHeaders.map((header) => header.text));

    messages.sort((a, b) => a.userId - b.userId);
    messages.forEach((message) => {
      exportData.push(
        alertMessageHeaders.map((header) => getItemPropertyByHeader(header, message)),
      );
    });

    exportData.push([]);

    // Timeline items
    exportData.push(['Comments and Updates']);
    exportData.push(timelineItemHeaders.map((header) => header.text));

    timelineItems.sort((a, b) => a.time - b.time);
    timelineItems.forEach((item) => {
      exportData.push(timelineItemHeaders.map((header) => getItemPropertyByHeader(header, item)));
    });

    // Export all
    csvExport.arrayToCsvFile(exportData, DELIMITER, filename, false, false);
  });
};
