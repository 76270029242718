import confirmDialog from '@/components/dialog/confirmDialog';
import textInput from '@/lib/ui/textArea';
import { translate as t } from '@/util/i18n';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';
import { ap, cr } from '@/lib/ui/dom';

const closeAlertDialog = (alertId) =>
  new Promise((resolve) => {
    let epilog = '';
    const onChangeEpilog = (event) => {
      epilog = event.target.value;
    };
    confirmDialog(
      t('closeAlert'),
      ap(
        cr(),
        t('closeAlertMsg'),
        textInput(
          {
            labelText: t('epilog'),
            helperText: t('epilogHelperText'),
            onChange: onChangeEpilog,
          },
          {
            name: 'epilog',
          },
        ).element,
      ),
      t('cancel'),
      t('closeAlert'),
    )
      .open()
      .then((confirm) => {
        if (confirm) {
          api
            .sendAlertUpdate({
              id: alertId,
              message: epilog,
              close: true,
              requestPosition: false,
            })
            .then(() => resolve())
            .catch((res) => errorMessage(t('closeAlertError'), t('closeAlertErrorMsg'), res));
        }
      });
  });

export default closeAlertDialog;
