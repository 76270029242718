<template>
  <div>
    <div :class="[alertTypeClass, { closed: isClosed }]" class="alertBox">
      <v-container fluid>
        <v-row>
          <GoBack />
          <img
            class="ma-2 ml-6"
            :src="emblem"
            width="40"
            min-width="40"
            height="40"
            min-height="40"
          />
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-6">
                <p class="text-h4" v-text="alert.title" />
                <p v-linkified class="text-h5" v-html="alert.message" />
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex align-center justify-start justify-md-end">
              <div class="mr-4">
                <p class="subtitle-1" v-text="alert.author" />
                <p class="subtitle-2 mb-0">{{ $t('sentDate') + ' ' + formattedDate }}</p>
              </div>

              <div>
                <profile-photo
                  :url="alert.authorProfilePhoto"
                  :user-name="alert.author"
                  :clickable="true"
                  size="80px"
                  :light-border="
                    alert.type === 'alarm' || alert.type === 'missionAlarm' || alert.type === 'sos'
                  "
                />
              </div>
            </div>
          </v-col>
        </v-row>

        <v-card
          v-if="alert.attachments && alert.attachments.length > 0"
          elevation="0"
          color="rgba(255, 255, 255, 0.3)"
          style="display: inline-block"
          class="mb-2 mt-4"
        >
          <attachment-grid
            :attachments="alert.attachments"
            preview
            :icon-color="attachmentIconColor"
          />
        </v-card>

        <v-row v-if="canUpdate">
          <v-col>
            <v-btn class="mr-4 my-2" outlined @click="sendUpdate">{{ $t('sendUpdate') }}</v-btn>
            <v-btn class="my-2" outlined @click="closeAlert">{{ $t('closeAlert') }} </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isClosed">
          <v-col cols="12">
            <v-alert dense text type="info" color="grey darken-3" class="mt-4">{{
              $t('alertClosed')
            }}</v-alert>
          </v-col>
          <v-col>
            <v-btn outlined class="mb-4" @click="deleteAlert">
              <v-icon left> fa-trash </v-icon>
              {{ $t('deleteAlert') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isMissionAlarm">
          <v-col
            ><v-btn outlined @click="viewMission"> {{ $t('viewMission') }} </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" class="pl-0">
            <div class="card commentsCard">
              <h3 class="pa-2 mt-3 text-align-center">
                {{ $t('comments') }}: {{ timelineItems.length }}
              </h3>
              <div v-if="timelineItems.length > 0">
                <v-timeline align-top dense class="pt-0 mt-4">
                  <v-timeline-item
                    v-for="(item, index) in timelineItems"
                    :key="index"
                    :color="commentTimelineColor"
                    small
                  >
                    <!-- <v-row class="pt-1"> -->
                    <v-card class="elevation-2 pa-4">
                      <v-row
                        v-if="item.type === 'update'"
                        justify="center"
                        align-content="center"
                        class="updateCommentHeader"
                      >
                        <strong>{{ $t('update!') }}</strong>
                      </v-row>
                      <v-row>
                        <v-col align-self="center">
                          <strong>{{ item.timeFormatted }}</strong>
                        </v-col>
                        <v-spacer />
                        <v-col class="d-flex justify-space-between align-center">
                          <div class="caption">
                            {{ item.author }}
                          </div>
                          <profile-photo
                            :url="item.authorProfilePhoto"
                            :alt="item.author"
                            light-border
                          />
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mt-4">
                        <v-col>
                          <div v-linkified v-html="item.message" />
                        </v-col>
                      </v-row>

                      <v-card
                        v-if="item.attachments && item.attachments.length > 0"
                        elevation="0"
                        color="rgba(0, 0, 0, 0.05)"
                        style="display: inline-block"
                        class="mt-4"
                      >
                        <attachment-grid :attachments="item.attachments" preview />
                      </v-card>
                    </v-card>
                    <!-- </v-row> -->
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-else class="emptystate">
                <v-icon x-large class="ml-1 pa-1 emtptyIcon">fa-comment-alt-lines</v-icon><br />
              </div>
            </div>
            <CommentInput
              v-if="!isClosed"
              class="mb-n4 mt-n2"
              :alert-id="alertId"
              @refreshComments="refreshComments"
            />
          </v-col>
          <v-col cols="12" md="6" class="pr-0">
            <div class="card">
              <v-container fluid>
                <v-row v-if="alert.stats">
                  <v-col v-if="alert.stats.excludedUsers > 0" cols="12">
                    <v-alert border="top" colored-border color="orange" elevation="1" class="mb-0">
                      {{ $t('noTargetUsersAlert', null, { count: alert.stats.excludedUsers }) }}
                    </v-alert>
                  </v-col>
                  <v-col cols="12">
                    <v-progress-linear
                      v-if="timerVar != null"
                      indeterminate
                      rounded
                      color="primary"
                    ></v-progress-linear>
                    <v-alert v-if="alert.stats.processing > 0" type="info" outlined
                      >{{ $t('fallbackSmsInfo') }}
                    </v-alert>
                    <h4 class="text-left">
                      Total:
                      {{ alert.stats.totalUsers - alert.stats.excludedUsers }}
                      users
                      <!--  TODO: pluralize -->
                    </h4>
                    <b-progress
                      show-value
                      :max="
                        alert.stats.deliveredTotal + alert.stats.failed + alert.stats.processing
                      "
                    >
                      <b-progress-bar
                        v-b-tooltip.hover
                        class="success-app"
                        :value="alert.stats.deliveredPush"
                        aria-label="Success"
                        title="Received in app"
                      />
                      <b-progress-bar
                        v-b-tooltip.hover
                        class="success-sms"
                        :value="alert.stats.deliveredDirectSms + alert.stats.deliveredFallbackSms"
                        aria-label="Success-sms"
                        title="Received as SMS"
                      />

                      <b-progress-bar
                        v-b-tooltip.hover
                        class="failed"
                        :value="alert.stats.failed"
                        aria-label="Failed"
                        title="Failed"
                      />
                      <b-progress-bar
                        v-b-tooltip.hover
                        title="Processing"
                        class="processing"
                        :value="alert.stats.processing"
                        aria-label="Processing"
                      />
                    </b-progress>
                  </v-col>
                  <v-col cols="12" class="pt-0" justify-space-between>
                    <div class="d-flex justify-space-between mb-6 flex-wrap">
                      <div class="legend-item">
                        <div class="legendBox success-app"></div>
                        <span class="legendText"
                          >Received in app:<b> {{ alert.stats.deliveredPush }}</b></span
                        >
                      </div>
                      <div class="legend-item">
                        <div class="legendBox success-sms"></div>
                        <span class="legendText"
                          >Received as SMS:<b>
                            {{
                              alert.stats.deliveredDirectSms + alert.stats.deliveredFallbackSms
                            }}</b
                          ></span
                        >
                      </div>
                      <div class="legend-item">
                        <div class="legendBox failed"></div>
                        <span class="legendText"
                          >Failed:<b> {{ alert.stats.failed }}</b></span
                        >
                      </div>
                      <div class="legend-item">
                        <div class="legendBox processing"></div>
                        <span class="legendText"
                          >Processing:<b> {{ alert.stats.processing }}</b></span
                        >
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-btn class="exportButton" color="primary" @click="exportReport">
                      <v-icon left> fa-file-download </v-icon>
                      {{ $t('export') }}</v-btn
                    >
                    <v-btn class="" color="primary" @click="openAlertResults">
                      {{ $t('details') }}</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div v-if="alert.position || alert.isLocationAlert">
                      <p
                        v-if="alert.isLocationAlert && targetLocationName.length > 0"
                        class="subtitle-1 mb-0 text-align-center"
                      >
                        {{
                          $t('predefinedLocationSelected', null, {
                            predefLocation: targetLocationName,
                          })
                        }}
                      </p>
                      <p
                        v-else-if="alert.isLocationAlert && targetLocationName === ''"
                        class="subtitle-1 mb-0 text-align-center"
                      >
                        {{ $t('customLocationSelected') }}
                      </p>
                      <p v-else-if="alert.position" class="subtitle-1 mb-0 text-align-center">
                        {{ $t('sendersPosition') }}
                      </p>

                      <LeafletMap
                        v-if="mapFinishedLoading"
                        :markers="markerPositions"
                        :polygon="targetPolygon"
                        :circle="targetCircle"
                        :area-name="targetLocationName"
                        class="map"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div ref="results" />
  </div>
</template>

<script>
import is from '@/lib/util/is';
import api from '@/util/api';
import moment from 'moment';
import { decodeEncodedNonAsciiCharacters } from '@/util/encoding';
import anchorme from 'anchorme';
import { translate as t } from '@/util/i18n';
import GoBack from '@/components/GoBack.vue';
import LeafletMap from '@/components/LeafletMap.vue';
import ImageDialog from '@/components/dialog/imageDialog';
import CommentInput from '@/components/CommentInput.vue';
import getUserProfile from '@/util/getUserProfile';
import { isAdmin } from '@/consts/roles';
import closeAlertDialog from './closeAlertDialog';
import sendAlertUpdateDialog from './sendAlertUpdateDialog';
import confirmDialog from '@/components/dialog/confirmDialog';
import exportResults from './alertResults/exportResults';
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import AttachmentGrid from '@/components/AttachmentGrid.vue';
// import ICountUp from 'vue-countup-v2';

const startOfToday = moment().startOf('day');
const isToday = (time) => moment().isSame(time, 'day');
const getTimeSince = (time) => {
  const daysSince = startOfToday.diff(moment(time).startOf('day'), 'days');
  let timeSince = '';

  if (daysSince === 0) {
    timeSince = t('today');
  } else if (daysSince === 1) {
    timeSince = t('yesterday');
  } else if (daysSince > 1) {
    timeSince = time.format('D. MMMM');
  }

  return timeSince;
};

export default {
  name: 'Alert',
  components: {
    GoBack,
    LeafletMap,
    CommentInput,
    ProfilePhoto,
    AttachmentGrid,
    // ICountUp,
  },
  data: () => ({
    alertId: '0',
    alert: {},
    timelineItems: [],
    cachedTimelineItemIds: [],
    events: [],
    canUpdate: false,
    mapFinishedLoading: false,
    targetLocationName: '',
    hasSetMapProps: false,
    markerPositions: [],
    targetPolygon: [],
    targetCircle: null,
    countupDelay: 800,
    countupOptions: {
      useEasing: true,
      useGrouping: true,
      separator: ' ',
      decimal: '.',
      prefix: '',
      suffix: '',
    },
    timerVar: null,
  }),
  computed: {
    emblem: function () {
      let filename = '';
      if (!this.isClosed) {
        filename =
          {
            urgentAlert: 'urgent-alert-darkYellow',
            sos: 'sos-white',
            alarm: 'alarm-white',
            missionAlarm: 'alarm-white',
          }[this.alert.type] || 'alarm-white';
      } else {
        filename =
          {
            urgentAlert: 'urgent-alert-gray',
            sos: 'sos-gray',
            alarm: 'alarm-gray',
            missionAlarm: 'alarm-gray',
          }[this.alert.type] || 'alarm-gray';
      }
      return require(`@/assets/images/${filename}.svg`);
    },
    alertTypeClass: function () {
      return (
        {
          urgentAlert: 'alert',
          sos: 'alarm',
          alarm: 'alarm',
          missionAlarm: 'alarm',
        }[this.alert.type] || 'alert'
      );
    },
    attachmentIconColor() {
      if (this.isClosed) {
        return 'gray800';
      } else {
        return (
          {
            urgentAlert: 'gray800',
            sos: 'white',
            alarm: 'white',
            missionAlarm: 'white',
          }[this.alert.type] || 'gray800'
        );
      }
    },
    commentTimelineColor: function () {
      if (this.isClosed) {
        return '#aaaaaa';
      } else {
        return (
          {
            urgentAlert: '#EFB145',
            sos: '#cc2e38',
            alarm: '#cc2e38',
            missionAlarm: '#cc2e38',
          }[this.alert.type] || '#EFB145'
        );
      }
    },
    formattedDate() {
      return moment(this.alert.date).format('DD.MM.YYYY HH:mm');
    },
    isClosed: function () {
      return this.alert.status === 'completed';
    },
    isMissionAlarm: function () {
      return this.alert.type === 'missionAlarm';
    },
    shouldRefreshStatus() {
      if (
        (!this.isClosed && this.alert.alertReceivedPercentage < 100) ||
        this.alert.smsFallbackReceivedPercentage < 100
      )
        return true;

      return false;
    },
  },
  mounted() {
    this.alert = 0;
    if (is.number(this.$route.params.id)) {
      this.alertId = this.$route.params.id;
    }

    this.loadData();
    this.getComments(this.alertId);
    this.subscribeToSignalREvents();
  },
  beforeDestroy() {
    if (this.timerVar) {
      clearTimeout(this.timerVar);
    }
  },
  methods: {
    moment,
    async loadData() {
      this.loading = true;
      const profile = await getUserProfile();
      api.getAlert(this.alertId).then((alert) => {
        const attachments = this.alert.attachments || alert.attachments;
        const authorProfilePhoto = this.alert.authorProfilePhoto || alert.authorProfilePhoto;
        this.alert = {
          ...alert,
          attachments,
          authorProfilePhoto,
        };

        this.loading = false;
        const role = profile.user.role;
        this.isAdmin = isAdmin(role);
        if ((this.isAdmin || profile.user.id === this.alert.authorId) && !this.isClosed) {
          this.canUpdate = true;
        }
        this.alert.alertReceivedPercentage = 0;
        let totalExpectedUsers = this.alert.stats.totalUsers - this.alert.stats.excludedUsers;
        if (totalExpectedUsers > 0) {
          this.alert.alertReceivedPercentage =
            (this.alert.stats.deliveredTotal / totalExpectedUsers) * 100;
        }

        if (!this.hasSetMapProps) {
          this.mapCenter = [60, 5];

          if (this.alert.position) {
            this.markerPositions = [];
            this.markerPositions.push({
              name: this.alert.author,
              lat: parseFloat(this.alert.position.lat),
              lng: parseFloat(this.alert.position.lng),
            });
            this.mapCenter = this.markerPositions[0];
            if (!this.alert.isLocationAlert) {
              this.mapFinishedLoading = true;
            }
          }
          if (this.alert.isLocationAlert) {
            if (this.alert.target.locations.length > 0) {
              this.handlePredefinedLocation(this.alert.target.locations[0]);
            } else if (this.alert.target.adHocLocation) {
              let location = this.alert.target.adHocLocation;
              this.parseLocationObject(location);
              this.mapFinishedLoading = true;
            }

            this.hasSetMapProps = true;
          }
        }
        if (!this.isClosed && this.alert.alertReceivedPercentage < 100) {
          this.timerVar = setTimeout(() => {
            this.loadData();
          }, 5000);
        } else {
          console.log('clearing timer', this.timerVar);
          clearTimeout(this.timerVar);
          this.timerVar = null;
        }
      });
    },
    handlePredefinedLocation(locationId) {
      api.getLocation(locationId).then((location) => {
        this.targetLocationName = location.name;
        this.parseLocationObject(location.geofence);
        this.mapFinishedLoading = true;
      });
    },
    parseLocationObject(location) {
      if (location.startsWith('P')) {
        let vertices = location.substring(2).split(';');
        let polygon = [];
        for (let i = 0; i < vertices.length; i++) {
          let vertex = vertices[i].split(',');
          polygon.push([parseFloat(vertex[0]), parseFloat(vertex[1])]);
        }
        this.targetPolygon = polygon;
      } else if (location.startsWith('C')) {
        let center = location.substring(2).split(',');
        this.targetCircle = {
          center: [parseFloat(center[0]), parseFloat(center[1])],
          radius: parseFloat(center[2]),
        };
      }
    },
    async getPredefinedLocation(locationId) {
      api.getLocation(locationId).then((preDefinedLocation) => {
        location = preDefinedLocation.geofence;
        return location;
      });
    },
    async getComments(alertId) {
      api
        .getComments({
          page: 1,
          pageSize: 1000,
          alertId,
        })
        .then((res) => {
          this.timelineItems = res.comments;
          this.timelineItems.forEach((item) => {
            const time = item.time;
            item.timeFormatted = isToday(time)
              ? time.format('HH:mm')
              : getTimeSince(time) + ' ' + time.format('HH:mm');
            let message = item.message;
            item.message = decodeEncodedNonAsciiCharacters(message);
            item.message = anchorme(message);
          });
        });
    },
    attachmentClick: function (image) {
      ImageDialog(image, 'test').open();
    },
    sendUpdate: function () {
      sendAlertUpdateDialog(this.alertId);
    },
    closeAlert: function () {
      closeAlertDialog(this.alertId).then(() => this.$router.go(-1));
    },
    deleteAlert: function () {
      confirmDialog(t('deleteAlert'), t('deleteAlertMsg'), t('cancel'), t('deleteAlert'))
        .open()
        .then((confirm) => {
          if (confirm) {
            api.deleteAlert(this.alertId).then(() => {
              this.$router.push('/alerts/closed');
            });
          }
        });
    },
    viewMission: function () {
      this.$router.push(`/missions/${this.alert.missionId}`);
    },
    refreshComments: function () {
      this.getComments(this.alertId);
    },
    exportReport: function () {
      exportResults(this.alertId);
    },
    openAlertResults() {
      this.$router.push('/alerts/delivery/' + this.alertId);
    },
    subscribeToSignalREvents() {
      this.events.forEach((event) => this.$signalR.off(event));

      const event = `newCommentAlertId:${this.alertId}`;
      this.events.push(event);

      this.$signalR.on(event, () => {
        this.refreshComments();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/scss/settings/colors';
.alertBox {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  border-radius: 0px 0px 8px 8px;
  &.alert {
    background: linear-gradient(180deg, #eec437 0%, #efb145 100%);
    p {
      color: #553e07 !important;
    }
    button {
      color: #553e07 !important;
    }
  }
  &.alarm {
    background: linear-gradient(180deg, #da2121 0%, #a91a10 100%);
    p {
      color: white;
    }
    button {
      color: white !important;
    }
  }
  &.closed {
    //background: linear-gradient(180deg, #dadada 0%, #ffffff 100%);
    background: linear-gradient(180deg, #c1c1c1 0%, #b7b7b7 100%);
    p {
      color: $colorGray900 !important;
    }
    button {
      color: $colorGray900 !important;
    }
  }
}
.commentsCard {
  padding: 16px 16px 0 16px !important;
  background-color: #f9f9f9;
}
.updateCommentHeader {
  background-color: $colorBlueDark;
  color: white;
  margin: -16px;
  padding: 8px;
}
.card {
  display: inline-block;
  width: 100%;
  margin: -12px 0px 0px 0px;
  padding: 15px;
  // background: white;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  border-radius: 0px 1px 8px 8px;
}
h3 {
  text-align: center;
}
.progressbar-text {
  color: white;
}
.attatchment-thumbnail {
  max-height: 80px;
  border-radius: 8px;
  margin-right: 8px;
}
.emptystate {
  min-height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}

.emtptyIcon {
  color: #c1c1c1;
  font-size: 120px !important;
}
.v-alert--dense {
  padding: 6px 8px;
}
.map {
  height: 30vh;
}
.exportButton {
  float: right;
}

.counter {
  display: block;
  font-size: 1.4em;
  text-align: center;
  font-weight: 600;
}
.count-card-text {
  font-size: 0.7em;
  text-align: center;
  margin-bottom: 0;
  color: #727272;
}
.counter-card {
  background-color: #ffffff;
  padding: 8px;
  border-radius: 8px;
  min-width: 150px;
}
.progress {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  height: 40px;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  border: 1px solid #cacaca;
  &.slim {
    height: 15px;
  }
}
.progress-bar {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #242424;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  font-size: 12pt;
  &.success-app {
    background-color: $deliveredApp !important;
    color: white;
  }
  &.success-sms {
    background-color: $deliveredSms !important;
    color: white;
  }

  &.failed {
    background-color: $failed !important;
    color: white;
  }
  &.processing {
    background-color: $sent !important;
  }
}

.tooltip.b-tooltip {
  display: block;
  opacity: 0.9;
  font-size: 0.8em;
  background-color: rgb(41, 41, 41);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  // Needed due to Bootstrap v4.4 reboot.css changes
  outline: 0;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.legendBox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 4px;
  border: 1px solid #ababab;
  &.success-app {
    background-color: $deliveredApp !important;
  }
  &.success-sms {
    background-color: $deliveredSms !important;
  }
  &.failed {
    background-color: $failed !important;
  }
  &.processing {
    background-color: $sent !important;
  }
}
</style>
