import { on, st, ap, cr, ac, dc } from './dom';
import input from './input';
import is from '../util/is';

const textArea = (opts, attrs, children) => {
  const wrapper = input(
    'textarea',
    opts,
    Object.assign(
      {
        rows: opts.minRows || 1,
      },
      attrs,
    ),
    children,
  );

  const inputElement = wrapper.inputElement;

  const resize = () => {
    st(inputElement, {
      height: 'auto',
    });
    st(inputElement, {
      height: inputElement.scrollHeight + 'px',
    });
  };

  const count = cr('span', null, inputElement.value.length);
  const counter = ap(cr('span', 'input-counter'), count, '/', `${opts.max}`);

  if (is.number(opts.max)) {
    inputElement.parentElement.insertBefore(counter, inputElement);

    on(inputElement, 'focus', () => {
      ac(counter, 'show');
    });

    on(inputElement, 'blur', () => {
      dc(counter, 'show');
    });
  }

  on(inputElement, 'input', (event) => {
    resize();
    if (is.number(opts.max)) {
      const target = event.target;

      if (target.value.length > opts.max) {
        ac(counter, 'overflow');
      } else {
        dc(counter, 'overflow');
      }

      count.innerHTML = target.value.length;
    }
  });

  return wrapper;
};

export default textArea;
